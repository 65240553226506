import { EventChangeArg, EventContentArg } from '@fullcalendar/core'
import { DateClickArg } from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import {
  addMinutes,
  compareDesc,
  differenceInMinutes,
  format,
  subMilliseconds
} from 'date-fns'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  useWatch
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { CalendarSelectEvent } from '~/core/ui/CalendarSelectEvent'
import { DashedButton } from '~/core/ui/DashedButton'
import { Dialog } from '~/core/ui/Dialog'
import { DropdownOptionAsync } from '~/core/ui/DropdownOptionAsync'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { NoFileInFolderIcon } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'
import { SingleDatePicker } from '~/core/ui/SingleDatePicker'
import { Skeleton } from '~/core/ui/Skeleton'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { capitalizeFirstLetter, currentTimeZone } from '~/core/utilities/common'
import {
  DATE_FORMAT_YYYYMMDD,
  defaultFormatDate,
  fullFormatDate,
  timeFormatDate,
  timeFullFormatDate
} from '~/core/utilities/format-date'
import useAttendeeEventHook from '~/lib/features/calendar/hooks/use-attendee-event'
import useScheduleInterviewManagement from '~/lib/features/calendar/hooks/use-schedule-interview-management'
import schemaTimeMethodCalendar from '~/lib/features/calendar/schema/validation-time-method-calendar'
import {
  AttendeeType,
  CalendarViewFormType,
  FirstStepFormType,
  InterviewDetailType
} from '~/lib/features/calendar/types'
import {
  DATE_TIME_TYPE_VALUE,
  DURATION_SELECTED_SLOT,
  INTERVIEW_STATE_VALUE
} from '~/lib/features/calendar/utilities/enum.cva'
import {
  calculateDurationBetweenTime,
  changeDuration,
  convertDurationAmountMinutes,
  convertTimezone,
  formatDateWithTz,
  formatStartEndDate,
  getTimeSelectOptions,
  mappingGroupHiringTeamAttendee,
  removeTzFromDate
} from '~/lib/features/calendar/utilities/helper-schedule-interview'
import useBoundStore from '~/lib/store'
import { checkTimeOccurInterview } from './DateTimeInterview'

const CalendarSkeleton = () => (
  <div className="flex h-[calc(100vh_-_80px)] w-full flex-col">
    <div className="flex items-center justify-between px-6 py-3">
      <Skeleton className="mb-3 h-8 w-1/3 rounded" />
      <Skeleton className="mb-3 h-8 w-1/3 rounded" />
    </div>
    <div className="flex flex-1 justify-between">
      <Skeleton className="mb-3 h-full w-[880px]" />
      <Skeleton className="mb-3 h-full w-[335px]" />
    </div>
  </div>
)

const EventCustom: FC<{
  e: EventContentArg
  duration: number
  startTime: string
  attendees: AttendeeType[]
  selectedDuration: SelectDurationType
  setSelectedDuration: (value: SelectDurationType) => void
  method: string
  caseEditGetPickedTimeSlot: boolean
}> = ({
  e,
  duration,
  startTime,
  attendees,
  selectedDuration,
  setSelectedDuration,
  method,
  caseEditGetPickedTimeSlot
}) => {
  const eventRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={eventRef}
      className={`group grid h-full w-full rounded ${
        e.event?.extendedProps?.id ? 'bg-blue-100' : 'bg-green-100'
      } ${
        duration !== 15 && e.event?.extendedProps?.id
          ? 'border-l-2 border-l-primary-400'
          : ''
      }`}>
      {e?.event?.start && timeFormatDate(e?.event?.start) === startTime && (
        <div
          className={`group flex h-full w-full justify-between rounded border-l border-t border-white px-1 py-0.5 text-left text-xs ${
            e.event?.extendedProps?.id
              ? 'bg-blue-100 text-blue-800'
              : 'bg-green-100 text-green-800 hover:bg-green-500 hover:text-white'
          } ${
            duration === 15 && !!e.event?.extendedProps?.id
              ? 'border-l-2 border-l-primary-400'
              : ''
          }`}>
          <div className="flex space-x-1">
            <Tooltip
              classNameConfig={{
                content: !!e.event?.extendedProps?.id ? '' : 'hidden'
              }}
              content={
                <>
                  {attendees.map((attendee: AttendeeType, index) => (
                    <div
                      key={`event-attendee-${index}`}
                      className="text-center">
                      <div>{attendee.fullName}</div>
                      <div>{attendee.email}</div>
                    </div>
                  ))}
                </>
              }>
              <span>{e?.event?.start && timeFormatDate(e?.event?.start)}</span>
            </Tooltip>
            <AvatarGroup
              size="2xs"
              maxUser={
                (eventRef?.current?.clientWidth || 0) > 100
                  ? 3
                  : attendees.length > 1
                  ? 0
                  : 1
              }
              source={attendees.map((attendee: AttendeeType) => ({
                id: attendee.id,
                alt: attendee?.fullName,
                src: attendee?.avatarVariants?.thumb?.url,
                defaultColour: attendee.defaultColour,
                tooltip: (
                  <div className="text-center">
                    <div>{attendee.fullName}</div>
                    <div>{attendee.email}</div>
                  </div>
                )
              }))}
            />
          </div>
          {method === DATE_TIME_TYPE_VALUE?.self_schedule &&
            !caseEditGetPickedTimeSlot &&
            !e.event?.extendedProps?.id && (
              <a
                className="hidden group-hover:inline-block"
                onClick={() => {
                  const key = e.event?.id
                  const resultObj = {} as SelectDurationType

                  Object.keys(selectedDuration)
                    .filter((keyItem) => keyItem !== key)
                    .forEach((keyItem) => {
                      resultObj[keyItem] = selectedDuration[keyItem]
                    })

                  setSelectedDuration(resultObj)
                }}>
                <IconWrapper name="X" size={16} className="text-white" />
              </a>
            )}
        </div>
      )}
    </div>
  )
}

export type SelectDurationType = {
  [key: string]: {
    keyTime: string
    start: Date
    startStr: string
    end: Date
    endStr: string
    rootTimeDate: {
      start: Date
      end: Date
    }
  }
}

export type DataSubmitCallbackType = CalendarViewFormType & {
  timeSlots?: Array<{
    fromDatetime?: string
    toDatetime?: string
    timezone?: string
  }>
  method?: string
}

const InfoRow: FC<{
  iconMenu?: LucideIconName
  label?: string
}> = ({ iconMenu, label }) => {
  return (
    <div className="flex items-start space-x-2">
      {iconMenu && (
        <div className="flex-none py-0.5">
          <IconWrapper name={iconMenu} size={16} className="text-gray-600" />
        </div>
      )}
      <Tooltip content={label}>
        <TypographyText className="line-clamp-1 text-sm text-gray-900">
          {label}
        </TypographyText>
      </Tooltip>
    </div>
  )
}

const renderKey = (startDate: Date, endDate: Date) => {
  return {
    key: `${fullFormatDate(startDate)}-${fullFormatDate(endDate)}-${
      new Date().getTime() / 1000
    }`,
    keyTime: `${fullFormatDate(startDate)}-${fullFormatDate(endDate)}`
  }
}

const DateTimeMethodCalendar: FC<{
  caseEditGetPickedTimeSlot?: boolean
  open: boolean
  onClose: (value: boolean) => void
  onSubmitCallback?: (data: DataSubmitCallbackType) => void
  getValues: UseFormGetValues<FirstStepFormType>
  interviewInfo?: InterviewDetailType
  timezoneOptions: ISelectOption[]
  isEditInterview?: boolean
}> = ({
  caseEditGetPickedTimeSlot,
  open,
  onClose,
  onSubmitCallback,
  getValues,
  interviewInfo,
  timezoneOptions,
  isEditInterview
}) => {
  const { t, i18n } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const divRef = useRef<HTMLDivElement>(null)
  const calendarRef = useRef<FullCalendar>(null)
  const [method, setMethod] = useState<string>('')
  const [showLoadingView, setShowLoadingView] = useState<boolean>(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValue = useMemo(() => getValues(), [open])
  const { attendeeEvents, setAttendeeEvents, fetchAttendeeEvent } =
    useAttendeeEventHook()
  const [selectedDuration, setSelectedDuration] = useState<SelectDurationType>(
    {}
  )

  const timeOptions = useMemo<Array<ISelectOption>>(
    () => getTimeSelectOptions(),
    []
  )
  const deviceTimezone = useMemo(() => {
    return convertTimezone(currentTimeZone)
  }, [])
  const [headerBarWidth, setHeaderBarWidth] = useState<Array<number>>()
  const { promiseAttendeesListOptions } = useScheduleInterviewManagement({
    interviewInfo
  })

  const renderEvent = (e: EventContentArg, method: string) => {
    const startTime = e.timeText?.split(' - ')?.[0]
    const attendees = e.event.extendedProps.eventAttendees || []
    const duration = differenceInMinutes(
      e.event.end || new Date(),
      e.event.start || new Date()
    )

    return (
      <EventCustom
        {...{
          e,
          duration,
          startTime,
          attendees,
          selectedDuration,
          setSelectedDuration,
          method,
          caseEditGetPickedTimeSlot: !!caseEditGetPickedTimeSlot
        }}
      />
    )
  }

  const changeSpecificTime = useCallback(
    ({
      date,
      startTime,
      endTime,
      setValue
    }: {
      date: Date
      startTime: string
      endTime: string
      setValue: UseFormSetValue<CalendarViewFormType>
    }) => {
      setValue('startTime', {
        value: startTime,
        supportingObj: {
          name: startTime
        }
      })
      setValue('endTime', {
        value: endTime,
        supportingObj: {
          name: endTime
        }
      })
      setValue('date', date)
    },
    []
  )

  const onHandleChangeEvent = useCallback(
    (e: EventChangeArg, setValue: UseFormSetValue<CalendarViewFormType>) => {
      if (
        method === DATE_TIME_TYPE_VALUE?.self_schedule &&
        !caseEditGetPickedTimeSlot
      ) {
        const { keyTime } = renderKey(
          e.event.start || new Date(),
          e.event.end || new Date()
        )

        const convertedSelectSlot = {
          ...selectedDuration,
          [e.event.id]: {
            ...selectedDuration[e.event.id],
            keyTime,
            start: e.event.start,
            end: e.event.end,
            startStr: e.event.startStr,
            endStr: e.event.endStr
          }
        }
        const selectedSlotValue = Object.values(convertedSelectSlot)
        setSelectedDuration(
          Object.keys(convertedSelectSlot).reduce(
            (result, keySlotTime, index) => {
              const firstIndex = selectedSlotValue.findIndex(
                (slot) =>
                  slot.keyTime === convertedSelectSlot[keySlotTime].keyTime
              )
              return firstIndex === index
                ? { ...result, [keySlotTime]: convertedSelectSlot[keySlotTime] }
                : result
            },
            {}
          )
        )
      } else {
        const date = e.event?.start ? new Date(e.event?.start) : new Date()
        const startTime = timeFormatDate(
          e.event?.start ? new Date(e.event?.start) : new Date()
        )
        const endTime = timeFormatDate(
          e.event?.end ? new Date(e.event?.end) : new Date()
        )
        changeSpecificTime({ date, startTime, endTime, setValue })
      }
      return Promise.resolve()
    },
    [selectedDuration, method, caseEditGetPickedTimeSlot]
  )

  const onCreateEvent = useCallback(
    ({
      timeSlot,
      durationWatcher,
      timezone,
      startTimeWatcher,
      endTimeWatcher,
      setValue
    }: {
      timeSlot: DateClickArg
      durationWatcher?: ISelectOption
      timezone?: string
      startTimeWatcher?: ISelectOption
      endTimeWatcher?: ISelectOption
      setValue: UseFormSetValue<CalendarViewFormType>
    }) => {
      const startDate = timeSlot.date
      const durationAmountMins = convertDurationAmountMinutes(
        durationWatcher?.value
      )
      const endDate = addMinutes(startDate, durationAmountMins)
      const { key, keyTime } = renderKey(startDate, endDate)

      const isUnselectDate = !!Object.keys(selectedDuration).find(
        (keyItem) => keyItem === key
      )

      if (isUnselectDate) {
      } else {
        if (caseEditGetPickedTimeSlot) {
          const date = timeSlot.date
          const durationInMins = calculateDurationBetweenTime({
            startTime: startTimeWatcher?.value,
            endTime: endTimeWatcher?.value,
            timeOptions: getTimeSelectOptions()
          })
          const startTime = timeFormatDate(date)
          const endTime = timeFormatDate(addMinutes(date, durationInMins))
          changeSpecificTime({ date, startTime, endTime, setValue })
        } else
          setSelectedDuration({
            ...selectedDuration,
            [key]: {
              keyTime: keyTime,
              start: startDate,
              startStr: formatDateWithTz(startDate, deviceTimezone),
              end: endDate,
              endStr: formatDateWithTz(endDate, deviceTimezone),
              rootTimeDate: {
                start: new Date(formatDateWithTz(startDate, timezone)),
                end: new Date(formatDateWithTz(endDate, timezone))
              }
            }
          })
      }
    },
    [deviceTimezone, selectedDuration, caseEditGetPickedTimeSlot]
  )

  const handleChangeTimezone = useCallback(
    (timezone?: string) => {
      //change position of now indicator
      const calendarAPI = calendarRef?.current?.getApi()
      //@ts-ignore
      calendarAPI.destroy()
      //@ts-ignore
      calendarAPI.render()

      if (attendeeEvents) {
        //Convert timezone attendee's events
        const attendeeEventConverted = (attendeeEvents?.collection || [])?.map(
          (event) => {
            const startDate = new Date(
              new Date(event?.extendedProps?.fromDatetime).toLocaleString(
                'en-us',
                {
                  timeZone: timezone
                }
              )
            )
            const endDate = new Date(
              new Date(event?.extendedProps?.toDatetime).toLocaleString(
                'en-us',
                {
                  timeZone: timezone
                }
              )
            )

            return {
              ...event,
              start: startDate,
              end: endDate
            }
          }
        )
        setAttendeeEvents({
          metadata: attendeeEvents.metadata,
          collection: attendeeEventConverted
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDuration, calendarRef, deviceTimezone, attendeeEvents]
  )

  const refetchAttendeeEvents = useCallback(
    ({
      timezone,
      attendees
    }: {
      timezone?: ISelectOption
      attendees?: MultiValue<ISelectOption>
    }) => {
      const today = new Date()
      const startWeek =
        calendarRef?.current?.getApi().view?.currentStart ||
        new Date(
          new Date(today.setDate(today.getDate() - today.getDay())).setHours(
            0,
            0,
            0
          )
        )
      const endWeek = calendarRef?.current?.getApi().view?.currentEnd
        ? subMilliseconds(calendarRef?.current?.getApi().view?.currentEnd, 1)
        : new Date(
            new Date(
              today.setDate(today.getDate() - today.getDay() + 6)
            ).setHours(23, 59, 59)
          )
      startWeek &&
        endWeek &&
        timezone?.value &&
        fetchAttendeeEvent({
          fromDatetime: formatDateWithTz(startWeek, timezone?.label),
          toDatetime: formatDateWithTz(endWeek, timezone?.label),
          attendeeIds:
            (attendees || [])?.map((attendee) => Number(attendee?.value)) || [],
          timezone: timezone?.value,
          ...(interviewInfo?.id
            ? { interviewId: Number(interviewInfo?.id) }
            : {})
        })
    },
    [calendarRef, interviewInfo]
  )

  const onDone = useCallback(
    (data: CalendarViewFormType) => {
      const interviewTz = convertTimezone(data.timezone?.value)

      const timeSlots = Object.values(selectedDuration).map((slot) => ({
        fromDatetime: formatDateWithTz(slot?.start, interviewTz),
        toDatetime: formatDateWithTz(slot?.end, interviewTz),
        timezone: data?.timezone?.value
      }))

      onSubmitCallback &&
        onSubmitCallback({
          ...data,
          timeSlots,
          method
        })
      onClose(false)
      return Promise.resolve()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDuration, method]
  )

  const onCheckTimeIsExisted = (keyTimeCheck: string) => {
    const timeSlots = interviewInfo?.interviewTimeSlots || []
    const tz = interviewInfo?.timezone
    return timeSlots.some((slot) => {
      const startTime: Date =
        slot.fromDatetime && tz
          ? new Date(
              new Date(slot.fromDatetime).toLocaleString('en-us', {
                timeZone: tz
              })
            )
          : new Date()
      const endTime =
        slot.toDatetime && tz
          ? new Date(
              new Date(slot.toDatetime).toLocaleString('en-us', {
                timeZone: tz
              })
            )
          : new Date()
      const { keyTime } = renderKey(startTime, endTime)
      return keyTimeCheck === keyTime
    })
  }

  useEffect(() => {
    if (open && !!defaultValue.method) {
      setMethod(defaultValue.method)

      const timeSlots = defaultValue.timeSlots || []

      const selectedConverted = timeSlots.reduce((result, slot) => {
        const startTime: Date =
          slot.fromDatetime && defaultValue.timezone
            ? removeTzFromDate(slot.fromDatetime)
            : new Date()
        const endTime =
          slot.toDatetime && defaultValue.timezone
            ? removeTzFromDate(slot.toDatetime)
            : new Date()
        const { key, keyTime } = renderKey(startTime, endTime)

        return {
          ...result,
          [key]: {
            keyTime,
            start: startTime,
            startStr: formatDateWithTz(startTime, deviceTimezone),
            end: endTime,
            endStr: formatDateWithTz(endTime, deviceTimezone),
            rootTimeDate: {
              start: slot.fromDatetime
                ? new Date(slot.fromDatetime)
                : new Date(),
              end: slot.toDatetime ? new Date(slot.toDatetime) : new Date()
            }
          }
        }
      }, {} as SelectDurationType)

      setSelectedDuration(selectedConverted)

      const dateActiveWeek =
        defaultValue.method === DATE_TIME_TYPE_VALUE.specific ||
        caseEditGetPickedTimeSlot
          ? new Date(
              `${
                defaultValue?.date &&
                format(defaultValue?.date, DATE_FORMAT_YYYYMMDD)
              }T${defaultValue?.startTime?.value}:00${deviceTimezone}`
            )
          : new Date(
              (defaultValue?.timeSlots || [])?.sort((a, b) =>
                compareDesc(
                  new Date(b.fromDatetime || ''),
                  new Date(a.fromDatetime || '')
                )
              )?.[0]?.fromDatetime || new Date()
            )

      setTimeout(() => {
        const calendarApi = calendarRef?.current?.getApi()
        calendarApi?.gotoDate(dateActiveWeek)
        calendarApi?.scrollToTime(timeFullFormatDate(dateActiveWeek))
        refetchAttendeeEvents({
          timezone: defaultValue?.timezone,
          attendees: defaultValue?.attendees
        })
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    defaultValue,
    calendarRef,
    caseEditGetPickedTimeSlot,
    deviceTimezone
  ])

  useEffect(() => {
    if (open) {
      refetchAttendeeEvents({
        timezone: defaultValue?.timezone,
        attendees: defaultValue?.attendees
      })
    }
  }, [open, defaultValue, calendarRef, refetchAttendeeEvents])

  const getWidthHeaderBar = () => {
    const headerBarEles = document.querySelectorAll('.fc-toolbar-chunk')

    if (headerBarEles) {
      const widthList = [] as Array<number>
      ;(headerBarEles || [])?.forEach((headerBarBlock: Element) => {
        widthList.push(headerBarBlock?.clientWidth)
      })
      setHeaderBarWidth(widthList)
    }
  }
  useEffect(() => {
    //show loading view
    if (open) {
      setTimeout(() => {
        setShowLoadingView(false)
      }, 500)
    } else {
      setShowLoadingView(true)
    }

    if (open && (headerBarWidth || [])?.length === 0) {
      const timeout = setTimeout(() => {
        getWidthHeaderBar()
      }, 500)
      return () => clearTimeout(timeout)
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return !defaultValue ? null : (
    <Dialog
      open={open}
      size="lg"
      onOpenChange={(value) => {
        onClose(value)
        setAttendeeEvents(undefined)
      }}
      isDivider={false}
      headingClassName="hidden"
      className="tablet:h-[calc(100vh_-_80px)] tablet:w-[1216px] tablet:max-w-[1216px]"
      childrenWrapperClassName="tablet:p-0"
      isPreventAutoFocusDialog={true}>
      {showLoadingView && <CalendarSkeleton />}
      <div
        className={`${
          showLoadingView ? 'hidden ' : ''
        }relative overflow-hidden rounded-lg`}>
        <DynamicImportForm
          defaultValue={defaultValue}
          schema={schemaTimeMethodCalendar(t)}
          onSubmit={onDone}>
          {({ formState, control, setValue, submit }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const startTimeWatcher = useWatch({
              control,
              name: 'startTime'
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const endTimeWatcher = useWatch({
              control,
              name: 'endTime'
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const dateWatcher = useWatch({
              control,
              name: 'date'
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const durationWatcher = useWatch({
              control,
              name: 'duration'
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const timezoneWatcher = useWatch({
              control,
              name: 'timezone'
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const attendeesWatcher = useWatch({
              control,
              name: 'attendees'
            })

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const now = useMemo(
              () =>
                new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: timezoneWatcher?.value
                  })
                ),
              [timezoneWatcher]
            )

            return (
              <>
                <div
                  className={`absolute top-0 flex h-[56px] items-center ${
                    headerBarWidth?.[0] ? '' : 'invisible'
                  }`}
                  style={{ left: `${36 + (headerBarWidth?.[0] || 0)}px` }}>
                  <TextButton
                    label={`${t('button:reload')}`}
                    iconMenus="RotateCw"
                    type="secondary"
                    size="sm"
                    underline={false}
                    onClick={() => {
                      refetchAttendeeEvents({
                        timezone: timezoneWatcher,
                        attendees: attendeesWatcher
                      })
                    }}
                  />
                </div>

                <div className="absolute !right-[178px] top-0 flex h-[56px] items-center">
                  <Controller
                    control={control}
                    name="timezone"
                    defaultValue={{} as ISelectOption}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NativeSelect
                          showClearIndicator={false}
                          menuPlacement="bottom"
                          configSelectOption={{
                            supportingText: ['name']
                          }}
                          classNameOverride={{
                            menu: 'right-0',
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          onChange={(value) => {
                            onChange(value)

                            handleChangeTimezone(
                              (value as ISelectOption)?.value
                            )
                          }}
                          size="sm"
                          value={value}
                          options={timezoneOptions}
                          variant="ghost"
                        />
                      )
                    }}
                  />
                </div>

                <CalendarSelectEvent
                  locale={i18n.language === 'ja' ? 'ja' : 'en-GB'}
                  textOverride={{
                    today: `${t('label:today')}`,
                    month: `${t('label:placeholder:month')}`,
                    day: `${capitalizeFirstLetter(t('label:day'))}`,
                    week: `${t('label:placeholder:week')}`
                  }}
                  calendarRef={calendarRef}
                  getWidthHeaderBar={getWidthHeaderBar}
                  height="calc(100vh - 80px)"
                  now={now}
                  initialDate={
                    method === DATE_TIME_TYPE_VALUE.specific ||
                    caseEditGetPickedTimeSlot
                      ? format(
                          new Date(
                            `${
                              dateWatcher &&
                              format(dateWatcher, DATE_FORMAT_YYYYMMDD)
                            }T${startTimeWatcher?.value}:00${deviceTimezone}`
                          ),
                          DATE_FORMAT_YYYYMMDD
                        )
                      : format(
                          Object.values(selectedDuration).sort(function (a, b) {
                            // Convert the date strings to Date objects
                            const dateA = a.start?.getTime()
                            const dateB = b.start.getTime()

                            // Subtract the dates to get a value that is either negative, positive, or zero
                            return dateA - dateB
                          })?.[0]?.start || new Date(),
                          DATE_FORMAT_YYYYMMDD
                        )
                  }
                  dataTransform={[
                    //@ts-ignore
                    ...(attendeeEvents?.collection || []),
                    ...(method === DATE_TIME_TYPE_VALUE?.self_schedule
                      ? caseEditGetPickedTimeSlot
                        ? [
                            {
                              groupId: 'pickedTimeSlot',
                              ...formatStartEndDate({
                                startTime: startTimeWatcher?.value,
                                endTime: endTimeWatcher?.value,
                                date: dateWatcher,
                                timezone: deviceTimezone,
                                timeOptions
                              })
                            }
                          ]
                        : Object?.keys(selectedDuration).map((key) => ({
                            groupId: key,
                            id: key,
                            start: selectedDuration[key]?.startStr,
                            end: selectedDuration[key]?.endStr
                          }))
                      : [
                          {
                            groupId: 'specificTimeSlot',
                            ...formatStartEndDate({
                              startTime: startTimeWatcher?.value,
                              endTime: endTimeWatcher?.value,
                              date: dateWatcher,
                              timezone: deviceTimezone,
                              timeOptions
                            })
                          }
                        ])
                  ]}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit'
                  }}
                  selectable={false}
                  renderEventContentTimeGrid={(e) => renderEvent(e, method)}
                  eventDurationEditable={
                    method === DATE_TIME_TYPE_VALUE.specific ||
                    caseEditGetPickedTimeSlot
                  }
                  onChangeEvent={(e) => onHandleChangeEvent(e, setValue)}
                  onClickCloseButton={() => onClose(false)}
                  setValueSearch={() => {
                    refetchAttendeeEvents({
                      timezone: timezoneWatcher,
                      attendees: attendeesWatcher
                    })
                  }}
                  onDateClick={(timeSlot) => {
                    if (method === DATE_TIME_TYPE_VALUE.self_schedule) {
                      const timezone = convertTimezone(timezoneWatcher?.value)
                      onCreateEvent({
                        timeSlot,
                        durationWatcher,
                        timezone,
                        startTimeWatcher,
                        endTimeWatcher,
                        setValue
                      })
                    } else {
                      const date = timeSlot.date
                      const durationInMins = calculateDurationBetweenTime({
                        startTime: startTimeWatcher?.value,
                        endTime: endTimeWatcher?.value,
                        timeOptions: getTimeSelectOptions()
                      })
                      const startTime = timeFormatDate(date)
                      const endTime = timeFormatDate(
                        addMinutes(date, durationInMins)
                      )
                      changeSpecificTime({ date, startTime, endTime, setValue })
                    }
                  }}
                  onEventResize={(eventInfo) => {
                    const date = eventInfo.event.start || new Date()
                    const endDate = eventInfo?.event.end || new Date()
                    const startTime = timeFormatDate(date)
                    const endTime = timeFormatDate(endDate)

                    changeSpecificTime({ date, startTime, endTime, setValue })
                  }}
                />
                <div className="absolute right-0 top-[56px] flex h-[calc(100%_-_56px)] w-[336px]  flex-col overflow-y-auto overflow-x-hidden border-l border-t border-l-gray-100 border-t-gray-100 pt-3">
                  {!caseEditGetPickedTimeSlot && !isEditInterview && (
                    <Tabs
                      className="border-b border-b-gray-100"
                      value={method}
                      onValueChange={setMethod}>
                      <TabsList size="sm" isFullWidth={true}>
                        {Object.values(DATE_TIME_TYPE_VALUE).map((method) => (
                          <TabsTrigger
                            key={`method-${method}`}
                            value={method}
                            className="h-4"
                            size="sm"
                            isFullWidth={true}
                            gapSize="md">
                            <TabsTriggerView
                              size="sm"
                              session={{
                                value: method,
                                label:
                                  method === DATE_TIME_TYPE_VALUE.self_schedule
                                    ? `${t(
                                        'interview:schedule_modal:candidate_self_schedule'
                                      )}`
                                    : `${t(
                                        'interview:schedule_modal:set_specific_time'
                                      )}`
                              }}
                            />
                          </TabsTrigger>
                        ))}
                      </TabsList>
                    </Tabs>
                  )}
                  <div className="grid gap-y-1 border-b border-b-gray-100 p-4">
                    <InfoRow
                      iconMenu="User"
                      label={interviewInfo?.profile?.fullName}
                    />
                    <InfoRow
                      iconMenu="Briefcase"
                      label={interviewInfo?.applicant?.job?.title}
                    />
                  </div>
                  <div className="flex-1 overflow-hidden">
                    <div className="flex h-full w-full  flex-col justify-between">
                      <div ref={divRef} className="flex-none p-4">
                        <TypographyText className="mb-1 text-xs font-medium uppercase text-gray-700">
                          {t('interview:schedule_modal:interview_info')}
                        </TypographyText>

                        <div className="flex flex-col gap-y-1">
                          <FormControlItem
                            labelRequired
                            label={`${t('interview:schedule_modal:attendees')}`}
                            iconMenus="Users"
                            mode="horizontal"
                            destructive={
                              formState.errors && !!formState.errors?.attendees
                            }
                            destructiveText={
                              formState.errors &&
                              (formState.errors.attendees?.message as string)
                            }>
                            <Controller
                              control={control}
                              name="attendees"
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <div className="-mt-2 flex px-2 py-1.5">
                                    <AvatarGroup
                                      size="xs"
                                      maxUser={999}
                                      className="flex-wrap space-x-2 space-x-reverse [&>div:first-child]:mr-2 [&>div]:mt-2"
                                      removeAvatar={(id) => {
                                        const newArr = (value || []).filter(
                                          (attendee) =>
                                            attendee.value !== String(id)
                                        )

                                        onChange(newArr)
                                      }}
                                      source={(value || []).map((attendee) => ({
                                        id: Number(attendee.value),
                                        alt: attendee?.supportingObj
                                          ?.description,
                                        src:
                                          attendee?.avatar ||
                                          attendee.avatarVariants?.thumb?.url,
                                        defaultColour:
                                          attendee?.supportingObj
                                            ?.defaultColour,
                                        tooltip: (
                                          <>
                                            <div>{`${attendee.supportingObj?.name}`}</div>
                                            <div>
                                              {
                                                attendee.supportingObj
                                                  ?.description
                                              }
                                            </div>
                                          </>
                                        )
                                      }))}
                                      extraControl={
                                        <DropdownOptionAsync
                                          className="w-[320px]"
                                          portalContainer={divRef?.current}
                                          {...(divRef?.current
                                            ? {
                                                dropdownMenuContainer:
                                                  divRef?.current
                                              }
                                            : {})}
                                          configSelect={{
                                            loadAsyncWhenRender: true,
                                            placeholder: `${t(
                                              'label:placeholder:select'
                                            )}`,
                                            configSelectOption: {
                                              option: 'checkbox',
                                              avatar: true,
                                              supportingText: [
                                                'name',
                                                'description'
                                              ]
                                            },
                                            classNameOverride: {
                                              loadingMessage: `${t(
                                                'label:loading'
                                              )}`,
                                              noOptionsMessage: `${t(
                                                'label:noOptions'
                                              )}`
                                            },
                                            onChange: (newValue) => {
                                              refetchAttendeeEvents({
                                                timezone: timezoneWatcher,
                                                attendees:
                                                  newValue as MultiValue<ISelectOption>
                                              })
                                              onChange(newValue)
                                            },
                                            promiseOptions: (param) => {
                                              const currentValue = getValues()

                                              const startWeek =
                                                calendarRef?.current?.getApi()
                                                  .view?.currentStart
                                              const endWeek =
                                                calendarRef?.current?.getApi()
                                                  .view?.currentEnd
                                                  ? subMilliseconds(
                                                      calendarRef?.current?.getApi()
                                                        .view?.currentEnd,
                                                      1
                                                    )
                                                  : new Date()

                                              const formatParam = {
                                                ...param,
                                                ...(interviewInfo?.applicant
                                                  ?.job?.id
                                                  ? {
                                                      jobId: Number(
                                                        interviewInfo?.applicant
                                                          ?.job?.id
                                                      )
                                                    }
                                                  : {}),
                                                ...(startWeek
                                                  ? {
                                                      fromDatetime:
                                                        formatDateWithTz(
                                                          startWeek,
                                                          timezoneWatcher?.label
                                                        )
                                                    }
                                                  : {}),
                                                ...(endWeek
                                                  ? {
                                                      toDatetime:
                                                        formatDateWithTz(
                                                          endWeek,
                                                          timezoneWatcher?.label
                                                        )
                                                    }
                                                  : {}),
                                                timezone:
                                                  currentValue?.timezone?.value
                                              }
                                              return promiseAttendeesListOptions(
                                                formatParam
                                              )
                                            },
                                            mappingGroupData: (data) =>
                                              mappingGroupHiringTeamAttendee(
                                                data,
                                                t
                                              ),
                                            size: 'sm',
                                            value: value,
                                            isMulti: true
                                          }}
                                          side="bottom"
                                          sideOffset={5}
                                          trigger={
                                            <Tooltip
                                              content={`${t(
                                                'tooltip:addAttendee'
                                              )}`}>
                                              <DashedButton
                                                iconMenus="Plus"
                                                size="sm"
                                              />
                                            </Tooltip>
                                          }
                                        />
                                      }
                                    />
                                  </div>
                                )
                              }}
                            />
                          </FormControlItem>
                          {DATE_TIME_TYPE_VALUE?.specific === method ||
                          caseEditGetPickedTimeSlot ? (
                            <>
                              <FormControlItem
                                label={`${t(
                                  'interview:schedule_modal:time_and_date'
                                )}`}
                                iconMenus="Clock"
                                mode="horizontal"
                                destructive={
                                  formState.errors &&
                                  !!formState.errors.startTime
                                }
                                destructiveText={
                                  formState.errors &&
                                  (formState.errors.startTime
                                    ?.message as string)
                                }>
                                <div className="">
                                  <div className="flex items-center">
                                    <div>
                                      <Controller
                                        control={control}
                                        name="startTime"
                                        defaultValue={undefined}
                                        render={({
                                          field: { onChange, value }
                                        }) => {
                                          return (
                                            <NativeSelect
                                              classNameOverride={{
                                                menu: '!min-w-[96px]',
                                                loadingMessage: `${t(
                                                  'label:loading'
                                                )}`,
                                                noOptionsMessage: `${t(
                                                  'label:noOptions'
                                                )}`
                                              }}
                                              onChange={(newValue) => {
                                                const posStartTimeIndex =
                                                  timeOptions.findIndex(
                                                    (time) =>
                                                      time.value ===
                                                      (
                                                        newValue as ISelectOption
                                                      )?.value
                                                  )

                                                if (
                                                  !checkTimeOccurInterview({
                                                    startTime: (
                                                      newValue as ISelectOption
                                                    )?.value,
                                                    endTime:
                                                      endTimeWatcher?.value
                                                  })
                                                ) {
                                                  setValue(
                                                    'endTime',
                                                    (posStartTimeIndex === 0 ||
                                                      posStartTimeIndex) &&
                                                      timeOptions[
                                                        posStartTimeIndex + 1
                                                      ]
                                                      ? timeOptions[
                                                          posStartTimeIndex + 1
                                                        ]
                                                      : value
                                                  )
                                                }

                                                onChange(newValue)
                                              }}
                                              size="sm"
                                              value={value}
                                              options={timeOptions}
                                              variant="ghost"
                                              showClearIndicator={false}
                                            />
                                          )
                                        }}
                                      />
                                    </div>
                                    <div className="mx-1 text-gray-400">-</div>
                                    <div>
                                      <Controller
                                        control={control}
                                        name="endTime"
                                        defaultValue={undefined}
                                        render={({
                                          field: { onChange, value }
                                        }) => {
                                          return (
                                            <NativeSelect
                                              onChange={onChange}
                                              classNameOverride={{
                                                menu: '!min-w-[96px]',
                                                loadingMessage: `${t(
                                                  'label:loading'
                                                )}`,
                                                noOptionsMessage: `${t(
                                                  'label:noOptions'
                                                )}`
                                              }}
                                              size="sm"
                                              value={value}
                                              options={timeOptions.filter(
                                                (time) => {
                                                  return checkTimeOccurInterview(
                                                    {
                                                      startTime:
                                                        startTimeWatcher?.value,
                                                      endTime: time.value
                                                    }
                                                  )
                                                }
                                              )}
                                              variant="ghost"
                                              showClearIndicator={false}
                                            />
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <FormControlItem>
                                    <Controller
                                      control={control}
                                      name="date"
                                      defaultValue={undefined}
                                      render={({
                                        field: { onChange, value }
                                      }) => {
                                        return (
                                          <SingleDatePicker
                                            locale={i18n.language}
                                            className="w-full"
                                            config={{
                                              defaultOpen: false,
                                              onChange: onChange,
                                              value: value,
                                              showClearIndicator: false
                                            }}
                                            placeholder={`${t(
                                              'label:placeholder:selectDate'
                                            )}`}
                                            size="sm"
                                            variant="ghost"
                                          />
                                        )
                                      }}
                                    />
                                  </FormControlItem>
                                </div>
                              </FormControlItem>
                            </>
                          ) : (
                            <>
                              <FormControlItem
                                label={`${t(
                                  'interview:schedule_modal:duration'
                                )}`}
                                iconMenus="Timer"
                                mode="horizontal"
                                destructive={
                                  formState.errors &&
                                  !!formState.errors?.duration
                                }
                                destructiveText={
                                  formState.errors &&
                                  (formState.errors.attendees
                                    ?.message as string)
                                }>
                                <Controller
                                  control={control}
                                  name="duration"
                                  render={({ field: { onChange, value } }) => {
                                    const mappings = DURATION_SELECTED_SLOT.map(
                                      (item) => ({
                                        ...item,
                                        supportingObj: {
                                          name: `${t(
                                            `interview:timeSlot:${item.value}`
                                          )}`
                                        }
                                      })
                                    )
                                    const filterDurationOption =
                                      mappings.filter(
                                        (item) =>
                                          String(item.value) ===
                                          String(value?.value)
                                      )?.[0]

                                    return (
                                      <NativeSelect
                                        size="sm"
                                        variant="ghost"
                                        options={mappings}
                                        value={filterDurationOption}
                                        onChange={(value) => {
                                          onChange(value)
                                          const convertedSelectSlots =
                                            changeDuration({
                                              selectedSlots: selectedDuration,
                                              duration: (value as ISelectOption)
                                                .value,
                                              deviceTimezone
                                            })
                                          setSelectedDuration(
                                            convertedSelectSlots
                                          )
                                        }}
                                        isClearable={false}
                                        classNameOverride={{
                                          loadingMessage: `${t(
                                            'label:loading'
                                          )}`,
                                          noOptionsMessage: `${t(
                                            'label:noOptions'
                                          )}`
                                        }}
                                      />
                                    )
                                  }}
                                />
                              </FormControlItem>
                            </>
                          )}
                        </div>
                      </div>
                      {method === DATE_TIME_TYPE_VALUE.self_schedule &&
                        !caseEditGetPickedTimeSlot && (
                          <div className="flex-1 overflow-y-auto border-t border-t-gray-100 p-4">
                            <div className="mb-2 flex items-center justify-between">
                              <TypographyText className="text-xs font-medium uppercase text-gray-700">
                                {t(
                                  'interview:schedule_modal:selected_slots_title'
                                )}
                              </TypographyText>
                              <TextButton
                                type="secondary"
                                size="sm"
                                underline={false}
                                label={`${t(
                                  'interview:schedule_modal:remove_all'
                                )}`}
                                onClick={() => setSelectedDuration({})}
                              />
                            </div>

                            {Object.keys(selectedDuration)?.length > 0 ? (
                              <div className="grid gap-y-2">
                                {Object.values(selectedDuration).map(
                                  (event, index) => (
                                    <div
                                      key={`event-${index}`}
                                      className="group flex items-center space-x-2">
                                      <IconWrapper
                                        name="Clock"
                                        size={16}
                                        className="flex-none text-gray-600"
                                      />
                                      <TypographyText className="text-sm text-gray-900">
                                        {timeFormatDate(new Date(event?.start))}{' '}
                                        - {timeFormatDate(new Date(event?.end))}
                                        ,{' '}
                                        {defaultFormatDate(
                                          new Date(event?.start)
                                        )}
                                      </TypographyText>
                                      <If
                                        condition={
                                          interviewInfo?.state ===
                                            INTERVIEW_STATE_VALUE.schedule_failed &&
                                          onCheckTimeIsExisted(event.keyTime)
                                        }>
                                        <div className="ml-2">
                                          <Badge
                                            color={'gray'}
                                            radius="circular"
                                            size="md">
                                            {t('label:unavailable')}
                                          </Badge>
                                        </div>
                                      </If>
                                      <a
                                        className="hidden hover:cursor-pointer group-hover:inline-block"
                                        onClick={() => {
                                          const key =
                                            Object.keys(selectedDuration)?.[
                                              index
                                            ]
                                          const resultObj =
                                            {} as SelectDurationType

                                          Object.keys(selectedDuration)
                                            .filter(
                                              (keyItem) => keyItem !== key
                                            )
                                            .forEach((keyItem) => {
                                              resultObj[keyItem] =
                                                selectedDuration[keyItem]
                                            })

                                          setSelectedDuration(resultObj)
                                        }}>
                                        <IconWrapper
                                          name="X"
                                          size={16}
                                          className="text-gray-600"
                                        />
                                      </a>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div className="flex items-center space-x-2">
                                <div className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-gray-100">
                                  <NoFileInFolderIcon />
                                </div>
                                <TypographyText className="text-sm text-gray-700">
                                  {t(
                                    'interview:schedule_modal:no_selected_slot'
                                  )}
                                </TypographyText>
                              </div>
                            )}
                          </div>
                        )}

                      <div className="p-4">
                        <Button
                          label={`${t('interview:schedule_modal:done')}`}
                          size="sm"
                          className="w-full"
                          onClick={submit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }}
        </DynamicImportForm>
      </div>
    </Dialog>
  )
}

export default DateTimeMethodCalendar
